import React, { useState, useEffect } from 'react';
import {
    Link,
    useLocation
} from "react-router-dom";

import AuthService from "../services/auth.service";
import { Trans, withTranslation, useTranslation } from 'react-i18next';
// import $ from 'jquery';


const AsideBarComponent = () => {
    //assigning location variable
    const location = useLocation();

    //destructuring pathname from location
    const { pathname } = location;
    const { i18n } = useTranslation();
    const { t } = useTranslation();
    //Javascript split method to get the name of the path in array
    const splitLocation = pathname.split("/");
    const [showModeratorBoard, setAsesorBoard] = useState(false);
    const [showAdminBoard, setAdminBoard] = useState(false);
    const [showNotariaBoard, setNotariaBoard] = useState(false);
    const [currentUser, setCurrentUser] = useState(null);
    const [toggleStatus, setToggleSideBar] = useState(null);
    const [showVigilanteBoard, setVigilanteBoard] = useState(false);


    function toggleSideBar() {
        let element = document.getElementById("sidebar");
        //element.classList.toggle("nav-collapsed");
        //element.classList.toggle("nav-lock");
        // OLD
        setExpanded(!expanded)
        if (expanded)
            setVisible(true);
        // setExpanded(prevExpanded => !prevExpanded);
    }

    const [visible, setVisible] = useState(false);
    const [expanded, setExpanded] = useState(false);


    const handleHover = () => {
        if (!expanded)
            setVisible(true);
    };

    const handleLeave = () => {
        if (!expanded)
            setVisible(false);
    };

    useEffect(() => {

        const user = AuthService.getCurrentUser();
        setCurrentUser(user);
        setAdminBoard(user.roles.includes("ROLE_ADMINISTRADOR"));
        setAsesorBoard(user.roles.includes("ROLE_ASESOR"));
        setVigilanteBoard(user.roles.includes("ROLE_VIGILANTE"));

        if (user.categorias) {
            setNotariaBoard(user.categorias.includes("CATEGORIA_NOTARIA"));
        }

        if (splitLocation[1] === "home") {
            const boxes = document.querySelectorAll('.collapsible-body');
            //$('#').sidenav('close');

            boxes.forEach(box => {
                // ✅ Remove class from each element
                //box.classList.remove('open');
                //box.classList.remove('bold');
                //box.classList.remove('active');
                box.style.display = '';
                // ✅ Add class to each element
                // box.classList.add('small');
            });
        }


    }, [pathname]);



    return (

        <aside id="sidebar" onMouseEnter={handleHover} onMouseLeave={handleLeave}
            className={`sidenav-main nav-expanded nav-lock nav-collapsible sidenav-light 
            sidenav-active-rounded ${visible ? 'nav-expanded' : 'nav-collapsed'}`} >
            <div className="brand-sidebar">
                <h1 className="logo-wrapper">
                    <Link className="brand-logo darken-1" to={"/home"}>
                        <img className="hide-on-med-and-down" src="/app-assets/images/logo/logo.png" alt="materialize logo" />
                        <img className="show-on-medium-and-down hide-on-med-and-up" src="/app-assets/images/logo/logo.png" alt="materialize logo" />
                        <span className="logo-text hide-on-med-and-down">VittAPP</span></Link>
                    {/*<button className="navbar-toggler btn-flat" onClick={toggleSideBar}>
                        <i className="material-icons">{expanded ? 'radio_button_checked' : 'radio_button_unchecked'}</i>
    </button>*/}
                </h1>
            </div>
            <ul className="sidenav sidenav-collapsible leftside-navigation collapsible sidenav-fixed menu-shadow" id="slide-out" data-menu="menu-navigation" data-collapsible="menu-accordion">

                <li className="navigation-header"><a className="navigation-header-text"><Trans>Apps</Trans></a><i className="navigation-header-icon material-icons">more_horiz</i>
                </li>

                {/*<li className={splitLocation[1] === "home" ? "bold active open" : "bold" }>
                    <Link className={splitLocation[1] === "home" ? "waves-effect waves-cyan bold open active gradient-shadow amber darken-3" : "waves-effect waves-cyan"} to={'/home'} style={{background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none'}}>
                        <i className="material-icons">home_outline</i>
                        <span className="menu-title" data-i18n="Mail">Inicio</span>
                    </Link>
                </li>*/}
                {(showAdminBoard || showVigilanteBoard) &&
                    <li className={splitLocation[1] === "administracion" ? "bold active open" : "bold"}>
                        <a className="collapsible-header waves-cyan " >
                            <i className="material-icons">admin_panel_settings</i>
                            <span className="menu-title" data-i18n="Menu levels">Administración</span>
                        </a>
                        <div className="collapsible-body">
                            <ul className="collapsible collapsible-sub" data-collapsible="accordion">

                                {/*{(showAdminBoard) &&*/}
                                {/*    <li className={splitLocation[2] === "torres" ? "active" : ""}>*/}
                                {/*        <a className="collapsible-header waves-cyan" >*/}
                                {/*            <i className="material-icons">menu</i>*/}
                                {/*            <span data-i18n="Second level child">Torres</span>*/}
                                {/*        </a>*/}
                                {/*        <div className="collapsible-body">*/}
                                {/*            <ul className="collapsible" data-collapsible="accordion">*/}
                                {/*                <li>*/}
                                {/*                    <Link to={"/administracion/torres/torres"} className={splitLocation[3] === "torres" ? "active gradient-shadow amber darken-3" : ""}*/}
                                {/*                        style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                {/*                        <i className="material-icons">radio_button_unchecked</i>*/}
                                {/*                        <span data-i18n="Third level">Torres</span>*/}
                                {/*                    </Link>*/}
                                {/*                </li>*/}
                                {/*            </ul>*/}
                                {/*        </div>*/}
                                {/*    </li>*/}
                                {/*}*/}

                                {(showAdminBoard) &&
                                    <li className={splitLocation[2] === "unidades" ? "active" : ""}>
                                        <a className="collapsible-header waves-cyan" >
                                            <i className="material-icons">menu</i>
                                            <span data-i18n="Second level child">Unidades</span>
                                        </a>
                                        <div className="collapsible-body">
                                            <ul className="collapsible" data-collapsible="accordion">
                                                <li>
                                                    <Link to={"/administracion/unidades/unidades"} className={splitLocation[3] === "unidades" ? "active gradient-shadow amber darken-3" : ""}
                                                        style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                        <i className="material-icons">radio_button_unchecked</i>
                                                        <span data-i18n="Third level">Unidades</span>
                                                    </Link>
                                                </li>
                                                {/*<li>
                                                    <Link to={"/administracion/unidades/saldo"} className={splitLocation[3] === "saldo" ? "active gradient-shadow amber darken-3" : ""}
                                                        style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                        <i className="material-icons">radio_button_unchecked</i>
                                                        <span data-i18n="Third level">Saldo</span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={"/administracion/unidades/tabla-de-cobranza"} className={splitLocation[3] === "tabla-de-cobranza" ? "active gradient-shadow amber darken-3" : ""}
                                                        style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                        <i className="material-icons">radio_button_unchecked</i>
                                                        <span data-i18n="Third level">Tabla de Cobranza</span>
                                                    </Link>
                                                </li>*/}
                                            </ul>
                                        </div>
                                    </li>
                                }

                                {(showAdminBoard) &&
                                    <li className={splitLocation[2] === "usuarios" ? "active" : ""}>
                                        <a className="collapsible-header waves-cyan" >
                                            <i className="material-icons">menu</i>
                                            <span data-i18n="Second level child">Usuarios</span>
                                        </a>
                                        <div className="collapsible-body">
                                            <ul className="collapsible" data-collapsible="accordion">
                                                <li>
                                                    <Link to={"/administracion/usuarios/directorio"} className={splitLocation[3] === "directorio" ? "active gradient-shadow amber darken-3" : ""}
                                                        style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                        <i className="material-icons">radio_button_unchecked</i>
                                                        <span data-i18n="Third level">Directorio</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                }

                                {(showAdminBoard) &&
                                    <li className={splitLocation[2] === "areas-comunes" ? "active" : ""}>
                                        <a className="collapsible-header waves-cyan" >
                                            <i className="material-icons">menu</i>
                                            <span data-i18n="Second level child">Áreas comunes</span>
                                        </a>
                                        <div className="collapsible-body">
                                            <ul className="collapsible" data-collapsible="accordion">
                                                <li>
                                                    <Link to={"/administracion/areas-comunes/administrar-areas"} className={splitLocation[3] === "administrar-areas" ? "active gradient-shadow amber darken-3" : ""}
                                                        style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                        <i className="material-icons">radio_button_unchecked</i>
                                                        <span data-i18n="Third level">Administrar Áreas</span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={"/administracion/areas-comunes/administrar-inventario"} className={splitLocation[3] === "administrar-inventario" ? "active gradient-shadow amber darken-3" : ""}
                                                        style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                        <i className="material-icons">radio_button_unchecked</i>
                                                        <span data-i18n="Third level">Administrar Inventario</span>
                                                    </Link>
                                                </li>
                                                {/*<li style={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'clip'}}>*/}
                                                {/*    <Link to={"/administracion/areas-comunes/reservaciones-pendientes"} className={splitLocation[3] === "reservaciones-pendientes" ? "active gradient-shadow amber darken-3" : ""}*/}
                                                {/*        style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                                {/*        <i className="material-icons">radio_button_unchecked</i>*/}
                                                {/*        <span data-i18n="Third level">Reservaciones Pendientes</span>*/}
                                                {/*    </Link>*/}
                                                {/*</li>*/}
                                            </ul>
                                        </div>
                                    </li>
                                }

                                {(showAdminBoard || showVigilanteBoard) &&
                                    <li className={splitLocation[2] === "tickets" ? "active" : ""}>
                                        <a className="collapsible-header waves-cyan" >
                                            <i className="material-icons">menu</i>
                                            <span data-i18n="Second level child">Tickets</span>
                                        </a>
                                        <div className="collapsible-body">
                                            <ul className="collapsible" data-collapsible="accordion">
                                                <li>
                                                    <Link to={"/administracion/tickets/gestionar-tickets"} className={splitLocation[3] === "gestionar-tickets" ? "active gradient-shadow amber darken-3" : ""}
                                                        style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                        <i className="material-icons">radio_button_unchecked</i>
                                                        <span data-i18n="Third level">Gestionar Tickets</span>
                                                    </Link>
                                                </li>
                                                {/*<li>
                                            <Link to={"/administracion/tickets/reporte-tickets"} className={splitLocation[3] === "reporte-tickets" ? "active gradient-shadow amber darken-3" : ""}
                                            style={{background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none'}}>
                                                <i className="material-icons">radio_button_unchecked</i>
                                                <span data-i18n="Third level">Reporte Tickets</span>
                                            </Link>
                                        </li>*/}
                                            </ul>
                                        </div>
                                    </li>
                                }
                                {(showAdminBoard || showVigilanteBoard) &&
                                    <li className={splitLocation[2] === "visitas" ? "active" : ""}>
                                        <a className="collapsible-header waves-cyan" >
                                            <i className="material-icons">menu</i>
                                            <span data-i18n="Second level child">Visitas</span>
                                        </a>
                                        <div className="collapsible-body">
                                            <ul className="collapsible" data-collapsible="accordion">
                                                <li>
                                                    <Link to={"/administracion/visitas/visitas"} className={splitLocation[3] === "visitas" ? "active gradient-shadow amber darken-3" : ""}
                                                        style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                        <i className="material-icons">radio_button_unchecked</i>
                                                        <span data-i18n="Third level">Visitas</span>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to={"/administracion/visitas/vehiculos"} className={splitLocation[3] === "vehiculos" ? "active gradient-shadow amber darken-3" : ""}
                                                        style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                        <i className="material-icons">radio_button_unchecked</i>
                                                        <span data-i18n="Third level">Vehículos</span>
                                                    </Link>
                                                </li>
                                                {/*<li>*/}
                                                {/*    <Link to={"/administracion/visitas/invitaciones"} className={splitLocation[3] === "invitaciones" ? "active gradient-shadow amber darken-3" : ""}*/}
                                                {/*        style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                                {/*        <i className="material-icons">radio_button_unchecked</i>*/}
                                                {/*        <span data-i18n="Third level">Invitaciones</span>*/}
                                                {/*    </Link>*/}
                                                {/*</li>*/}
                                                <li>
                                                    <Link to={"/administracion/visitas/bitacora"} className={splitLocation[3] === "bitacora" ? "active gradient-shadow amber darken-3" : ""}
                                                        style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                        <i className="material-icons">radio_button_unchecked</i>
                                                        <span data-i18n="Third level">Bitácora</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                }

                                {(showAdminBoard) &&
                                    <li className={splitLocation[2] === "airbnb" ? "active" : ""}>
                                        <a className="collapsible-header waves-cyan" >
                                            <i className="material-icons">menu</i>
                                            <span data-i18n="Second level child">Airbnb</span>
                                        </a>
                                        <div className="collapsible-body">
                                            <ul className="collapsible" data-collapsible="accordion">
                                                <li>
                                                    <Link to={"/administracion/airbnb"} className={splitLocation[2] === "airbnb" ? "active gradient-shadow amber darken-3" : ""}
                                                          style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                        <i className="material-icons">radio_button_unchecked</i>
                                                        <span data-i18n="Third level">Airbnb</span>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                }

                            </ul>
                        </div>
                    </li>
                }

                {showAdminBoard &&
                    <li className={splitLocation[1] === "finanzas" ? "bold active open" : "bold"}>
                        <a className="collapsible-header waves-cyan " >
                            <i className="material-icons">payments</i>
                            <span className="menu-title" data-i18n="Menu levels">Finanzas</span>
                        </a>
                        <div className="collapsible-body">
                            <ul className="collapsible collapsible-sub" data-collapsible="accordion">

                                <li className={splitLocation[2] === "ingresos" ? "active" : ""}>
                                    <a className="collapsible-header waves-cyan" >
                                        <i className="material-icons">menu</i>
                                        <span data-i18n="Second level child">Ingresos</span>
                                    </a>
                                    <div className="collapsible-body">
                                        <ul className="collapsible" data-collapsible="accordion">
                                            <li>
                                                <Link to={"/finanzas/ingresos/capturar-ingresos"} className={splitLocation[3] === "capturar-ingresos" ? "active gradient-shadow amber darken-3" : ""}
                                                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Capturar Ingresos</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/finanzas/ingresos/facturar-ingresos"} className={splitLocation[3] === "facturar-ingresos" ? "active gradient-shadow amber darken-3" : ""}
                                                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Facturar Ingresos</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/finanzas/ingresos/comprobantes-de-pago"} className={splitLocation[3] === "comprobantes-de-pago" ? "active gradient-shadow amber darken-3" : ""}
                                                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Comprobantes De Pago</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                                <li className={splitLocation[2] === "egresos" ? "active" : ""}>
                                    <a className="collapsible-header waves-cyan" >
                                        <i className="material-icons">menu</i>
                                        <span data-i18n="Second level child">Egresos</span>
                                    </a>
                                    <div className="collapsible-body">
                                        <ul className="collapsible" data-collapsible="accordion">
                                            <li>
                                                <Link to={"/finanzas/egresos/capturar-egresos"} className={splitLocation[3] === "capturar-egresos" ? "active gradient-shadow amber darken-3" : ""}
                                                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Capturar Egresos</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/finanzas/egresos/cheques-en-transito"} className={splitLocation[3] === "cheques-en-transito" ? "active gradient-shadow amber darken-3" : ""}
                                                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Cheques En Tránsito</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/finanzas/egresos/catalogo-de-proveedores"} className={splitLocation[3] === "catalogo-de-proveedores" ? "active gradient-shadow amber darken-3" : ""}
                                                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Catálogo de Proveedores</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                                <li className={splitLocation[2] === "cuotas" ? "active" : ""}>
                                    <a className="collapsible-header waves-cyan" >
                                        <i className="material-icons">menu</i>
                                        <span data-i18n="Second level child">Cuotas</span>
                                    </a>
                                    <div className="collapsible-body">
                                        <ul className="collapsible" data-collapsible="accordion">
                                            <li className={"text-sub"}>
                                                <Link to={"/finanzas/cuotas/generar-cuotas"} className={splitLocation[3] === "generar-cuotas" ? "active gradient-shadow amber darken-3" : ""}
                                                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Generar Cuotas</span>
                                                </Link>
                                            </li>
                                            <li style={{
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap', textOverflow: 'clip'
                                            }}>
                                                <Link to={"/finanzas/cuotas/generar-cuotas-servicios"} className={splitLocation[3] === "generar-cuotas-servicios" ? "active gradient-shadow amber darken-3" : ""}
                                                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Generar Cuotas Servicios</span>
                                                </Link>
                                            </li>
                                            <li style={{
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap', textOverflow: 'clip'
                                            }}>
                                                <Link to={"/finanzas/cuotas/generar-recargos"} className={splitLocation[3] === "generar-recargos" ? "active gradient-shadow amber darken-3" : ""}
                                                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Generar Recargos</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                                {/*<li className={splitLocation[2] === "presupuestos" ? "active" : ""}>*/}
                                {/*    <a className="collapsible-header waves-cyan" >*/}
                                {/*        <i className="material-icons">menu</i>*/}
                                {/*        <span data-i18n="Second level child">Presupuestos</span>*/}
                                {/*    </a>*/}
                                {/*    <div className="collapsible-body">*/}
                                {/*        <ul className="collapsible" data-collapsible="accordion">*/}
                                {/*            <li>*/}
                                {/*                <Link to={"/finanzas/presupuestos/capturar-presupuestos"} className={splitLocation[3] === "capturar-presupuestos" ? "active gradient-shadow amber darken-3" : ""}*/}
                                {/*                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                {/*                    <i className="material-icons">radio_button_unchecked</i>*/}
                                {/*                    <span data-i18n="Third level">Capturar Presuspuestos</span>*/}
                                {/*                </Link>*/}
                                {/*            </li>*/}
                                {/*        </ul>*/}
                                {/*    </div>*/}
                                {/*</li>*/}

                                {/*<li className={splitLocation[2] === "cuentas" ? "active" : ""}>*/}
                                {/*    <a className="collapsible-header waves-cyan" >*/}
                                {/*        <i className="material-icons">menu</i>*/}
                                {/*        <span data-i18n="Second level child">Cuentas</span>*/}
                                {/*    </a>*/}
                                {/*    <div className="collapsible-body">*/}
                                {/*        <ul className="collapsible" data-collapsible="accordion">*/}
                                {/*            <li>*/}
                                {/*                <Link to={"/finanzas/cuentas/bancos"} className={splitLocation[3] === "bancos" ? "active gradient-shadow amber darken-3" : ""}*/}
                                {/*                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                {/*                    <i className="material-icons">radio_button_unchecked</i>*/}
                                {/*                    <span data-i18n="Third level">Banco</span>*/}
                                {/*                </Link>*/}
                                {/*            </li>*/}
                                {/*            <li>*/}
                                {/*                <Link to={"/finanzas/cuentas/caja-chica"} className={splitLocation[3] === "caja-chica" ? "active gradient-shadow amber darken-3" : ""}*/}
                                {/*                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                {/*                    <i className="material-icons">radio_button_unchecked</i>*/}
                                {/*                    <span data-i18n="Third level">Caja Chica</span>*/}
                                {/*                </Link>*/}
                                {/*            </li>*/}
                                {/*        </ul>*/}
                                {/*    </div>*/}
                                {/*</li>*/}

                                {/*<li className={splitLocation[2] === "condonaciones" ? "active" : ""}>*/}
                                {/*    <a className="collapsible-header waves-cyan" >*/}
                                {/*        <i className="material-icons">menu</i>*/}
                                {/*        <span data-i18n="Second level child">Condonaciones</span>*/}
                                {/*    </a>*/}
                                {/*    <div className="collapsible-body">*/}
                                {/*        <ul className="collapsible" data-collapsible="accordion">*/}
                                {/*            <li>*/}
                                {/*                <Link to={"/finanzas/condonaciones/condonar-deudas"} className={splitLocation[3] === "condonar-deudas" ? "active gradient-shadow amber darken-3" : ""}*/}
                                {/*                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                {/*                    <i className="material-icons">radio_button_unchecked</i>*/}
                                {/*                    <span data-i18n="Third level">Condonar Deudas</span>*/}
                                {/*                </Link>*/}
                                {/*            </li>*/}
                                {/*            <li>*/}
                                {/*                <Link to={"/finanzas/condonaciones/condonacion-historica"} className={splitLocation[3] === "condonar-historica" ? "active gradient-shadow amber darken-3" : ""}*/}
                                {/*                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                {/*                    <i className="material-icons">radio_button_unchecked</i>*/}
                                {/*                    <span data-i18n="Third level">Condonación Histórico</span>*/}
                                {/*                </Link>*/}
                                {/*            </li>*/}
                                {/*        </ul>*/}
                                {/*    </div>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                    </li>
                }

                {showAdminBoard &&
                    <li className={splitLocation[1] === "reportes" ? "bold active open" : "bold"}>
                        <a className="collapsible-header waves-cyan " >
                            <i className="material-icons">analytics</i>
                            <span className="menu-title" data-i18n="Menu levels">Reportes</span>
                        </a>
                        <div className="collapsible-body">
                            <ul className="collapsible collapsible-sub" data-collapsible="accordion">

                                {/*<li className={splitLocation[2] === "balance" ? "active" : ""}>*/}
                                {/*    <a className="collapsible-header waves-cyan" >*/}
                                {/*        <i className="material-icons">menu</i>*/}
                                {/*        <span data-i18n="Second level child">Balance</span>*/}
                                {/*    </a>*/}
                                {/*    <div className="collapsible-body">*/}
                                {/*        <ul className="collapsible" data-collapsible="accordion">*/}
                                {/*            <li style={{*/}
                                {/*                overflow: 'hidden',*/}
                                {/*                whiteSpace: 'nowrap', textOverflow: 'clip'*/}
                                {/*            }}>*/}
                                {/*                <Link to={"/reportes/balance/resumen-del-condominio"} className={splitLocation[3] === "resumen-del-condominio" ? "active gradient-shadow amber darken-3" : ""}*/}
                                {/*                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                {/*                    <i className="material-icons">radio_button_unchecked</i>*/}
                                {/*                    <span data-i18n="Third level">Resumen Del Condominio</span>*/}
                                {/*                </Link>*/}
                                {/*            </li>*/}
                                {/*            <li>*/}
                                {/*                <Link to={"/reportes/balance/reporte-mensual"} className={splitLocation[3] === "reporte-mensual" ? "active gradient-shadow amber darken-3" : ""}*/}
                                {/*                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                {/*                    <i className="material-icons">radio_button_unchecked</i>*/}
                                {/*                    <span data-i18n="Third level">Reporte Mensual</span>*/}
                                {/*                </Link>*/}
                                {/*            </li>*/}
                                {/*            <li>*/}
                                {/*                <Link to={"/reportes/balance/reporte-anual"} className={splitLocation[3] === "reporte-anual" ? "active gradient-shadow amber darken-3" : ""}*/}
                                {/*                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                {/*                    <i className="material-icons">radio_button_unchecked</i>*/}
                                {/*                    <span data-i18n="Third level">Reporte Anual</span>*/}
                                {/*                </Link>*/}
                                {/*            </li>*/}
                                {/*        </ul>*/}
                                {/*    </div>*/}
                                {/*</li>*/}

                                <li className={splitLocation[2] === "reportes-de-ingresos" ? "active" : ""}>
                                    <a className="collapsible-header waves-cyan">
                                        <i className="material-icons">menu</i>
                                        <span data-i18n="Second level child">Reportes de Ingresos</span>
                                    </a>
                                    <div className="collapsible-body">
                                        <ul className="collapsible" data-collapsible="accordion">
                                            <li>
                                                <Link to={"/reportes/reportes-de-ingresos"}
                                                      className={splitLocation[2] === "reportes-de-ingresos" ? "active gradient-shadow amber darken-3" : ""}
                                                      style={{
                                                          background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%',
                                                          boxShadow: 'none'
                                                      }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Reporte de Ingresos</span>
                                                </Link>
                                            </li>
                                            {/* <li>
                                                <Link to={"/reportes/reportes-de-ingresos/ingresos-facturados"}
                                                      className={splitLocation[3] === "ingresos-facturados" ? "active gradient-shadow amber darken-3" : ""}
                                                      style={{
                                                          background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%',
                                                          boxShadow: 'none'
                                                      }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Ingresos Facturados</span>
                                                </Link>
                                            </li> */}
                                        </ul>
                                    </div>
                                </li>

                                <li className={splitLocation[2] === "reporte-de-egresos" ? "active" : ""}>
                                    <a className="collapsible-header waves-cyan">
                                        <i className="material-icons">menu</i>
                                        <span data-i18n="Second level child">Reportes de Egresos</span>
                                    </a>
                                    <div className="collapsible-body">
                                        <ul className="collapsible" data-collapsible="accordion">
                                            <li>
                                                <Link to={"/reportes/reportes-de-egresos"}
                                                      className={splitLocation[2] === "reportes-de-egresos" ? "active gradient-shadow amber darken-3" : ""}
                                                      style={{
                                                          background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%',
                                                          boxShadow: 'none'
                                                      }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Reporte de Egresos</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                                <li className={splitLocation[3] === "reporte-de-cuotas" ? "active" : ""}>
                                    <a className="collapsible-header waves-cyan">
                                        <i className="material-icons">menu</i>
                                        <span data-i18n="Second level child">Reportes de Cuotas</span>
                                    </a>
                                    <div className="collapsible-body">
                                        <ul className="collapsible" data-collapsible="accordion">
                                            <li>
                                                <Link to={"/reportes/reportes-de-cuotas"}
                                                      className={splitLocation[2] === "reportes-de-cuotas" ? "active gradient-shadow amber darken-3" : ""}
                                                      style={{
                                                          background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%',
                                                          boxShadow: 'none'
                                                      }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Cuotas Generadas</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                                <li className={splitLocation[4] === "estados-de-cuenta" ? "active" : ""}>
                                    <a className="collapsible-header waves-cyan">
                                        <i className="material-icons">menu</i>
                                        <span data-i18n="Second level child">Estado de Cuenta</span>
                                    </a>
                                    <div className="collapsible-body">
                                        <ul className="collapsible" data-collapsible="accordion">
                                            <li>
                                                <Link to={"/reportes/estados-de-cuenta"}
                                                      className={splitLocation[2] === "estados-de-cuenta" ? "active gradient-shadow amber darken-3" : ""}
                                                      style={{
                                                          background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%',
                                                          boxShadow: 'none'
                                                      }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Estado de Cuenta</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                                <li className={splitLocation[4] === "catalogos" ? "active" : ""}>
                                    <a className="collapsible-header waves-cyan">
                                        <i className="material-icons">menu</i>
                                        <span data-i18n="Second level child">Reportes de Catálogos</span>
                                    </a>
                                    <div className="collapsible-body">
                                        <ul className="collapsible" data-collapsible="accordion">
                                            <li>
                                                <Link to={"/reportes/catalogos/catalogo-residentes/"}
                                                      className={splitLocation[3] === "catalogo-residentes" ? "active gradient-shadow amber darken-3" : ""}
                                                      style={{
                                                          background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%',
                                                          boxShadow: 'none'
                                                      }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Catálogo Residentes</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/reportes/catalogos/catalogo-inventario/"}
                                                      className={splitLocation[3] === "catalogo-inventario" ? "active gradient-shadow amber darken-3" : ""}
                                                      style={{
                                                          background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%',
                                                          boxShadow: 'none'
                                                      }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Catálogo Inventario</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/reportes/catalogos/catalogo-unidades/"}
                                                      className={splitLocation[3] === "catalogo-unidades" ? "active gradient-shadow amber darken-3" : ""}
                                                      style={{
                                                          background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%',
                                                          boxShadow: 'none'
                                                      }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Catálogo Unidades</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                                <li className={splitLocation[3] === "reservaciones-airbnb" ? "active" : ""}>
                                    <a className="collapsible-header waves-cyan">
                                        <i className="material-icons">menu</i>
                                        <span data-i18n="Second level child">Reportes Airbnb</span>
                                    </a>
                                    <div className="collapsible-body">
                                        <ul className="collapsible" data-collapsible="accordion">
                                            <li>
                                                <Link to={"/reportes/reportes-airbnb/reservaciones-airbnb/"}
                                                      className={splitLocation[3] === "reservaciones-airbnb" ? "active gradient-shadow amber darken-3" : ""}
                                                      style={{
                                                          background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%',
                                                          boxShadow: 'none'
                                                      }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Reservaciones Airbnb</span>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                </li>

                            </ul>
                        </div>
                    </li>
                }

                {(showAdminBoard || showVigilanteBoard) &&
                    <li className={splitLocation[1] === "comunicacion" ? "bold active open" : "bold"}>
                        <a className="collapsible-header waves-cyan ">
                            <i className="material-icons">newspaper</i>
                            <span className="menu-title" data-i18n="Menu levels">{t('menu.communication')}</span>
                        </a>
                        <div className="collapsible-body">
                            <ul className="collapsible collapsible-sub" data-collapsible="accordion">

                                <li className={splitLocation[2] === "anuncios-mensajes" ? "active" : ""}>
                                    <a className="collapsible-header waves-cyan">
                                        <i className="material-icons">menu</i>
                                        <span data-i18n="Second level child">Anuncios y Mensajes</span>
                                    </a>
                                    <div className="collapsible-body">
                                        <ul className="collapsible" data-collapsible="accordion">
                                            <li>
                                                <Link to={"/comunicacion/anuncios-mensajes/anuncios-generales"}
                                                      className={splitLocation[3] === "anuncios-generales" ? "active gradient-shadow amber darken-3" : ""}
                                                      style={{background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Anuncios Generales</span>
                                                </Link>
                                            </li>
                                            {(showAdminBoard) &&
                                                <li>
                                                    <Link to={"/comunicacion/anuncios-mensajes/categoria-de-anuncios"} className={splitLocation[3] === "categoria-de-anuncios" ? "active gradient-shadow amber darken-3" : ""}
                                                        style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                        <i className="material-icons">radio_button_unchecked</i>
                                                        <span data-i18n="Third level">Categoria de Anuncios</span>
                                                    </Link>
                                                </li>
                                            }
                                            {(showAdminBoard) &&
                                                <li>
                                                    <Link to={"/comunicacion/anuncios-mensajes/blog-de-noticias"} className={splitLocation[3] === "blog-de-noticias" ? "active gradient-shadow amber darken-3" : ""}
                                                        style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                        <i className="material-icons">radio_button_unchecked</i>
                                                        <span data-i18n="Third level">Blog de Noticias</span>
                                                    </Link>
                                                </li>
                                            }
                                        </ul>
                                    </div>
                                </li>

                                {/*<li className={splitLocation[2] === "documentos" ? "active" : ""}>
                                <a className="collapsible-header waves-cyan" >
                                    <i className="material-icons">menu</i>
                                    <span data-i18n="Second level child">Documentos</span>
                                </a>
                                <div className="collapsible-body">
                                    <ul className="collapsible" data-collapsible="accordion">
                                        <li>
                                            <Link to={"/comunicacion/documentos/documentos-generales"} className={splitLocation[3] === "documentos-generales" ? "active gradient-shadow amber darken-3" : ""}
                                            style={{background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none'}}>
                                                <i className="material-icons">radio_button_unchecked</i>
                                                <span data-i18n="Third level">Documentos Generales</span>
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to={"/comunicacion/documentos/documentos-por-unidad"} className={splitLocation[3] === "documentos-por-unidad" ? "active gradient-shadow amber darken-3" : ""}
                                            style={{background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none'}}>
                                                <i className="material-icons">radio_button_unchecked</i>
                                                <span data-i18n="Third level">Documentos Por Unidad</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>*/}

                                {/*<li className={splitLocation[2] === "encuestas" ? "active" : ""}>
                                <a className="collapsible-header waves-cyan" >
                                    <i className="material-icons">menu</i>
                                    <span data-i18n="Second level child">Encuestas</span>
                                </a>
                                <div className="collapsible-body">
                                    <ul className="collapsible" data-collapsible="accordion">
                                        <li>
                                            <Link to={"/comunicacion/encuestas/encuestas"} className={splitLocation[3] === "encuestas" ? "active gradient-shadow amber darken-3" : ""}
                                            style={{background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none'}}>
                                                <i className="material-icons">radio_button_unchecked</i>
                                                <span data-i18n="Third level">Encuestas</span>
                                            </Link>
                                        </li>
                                    </ul>
                                </div>
                            </li>*/}

                            </ul>
                        </div>
                    </li>
                }

                {showAdminBoard &&
                    <li className={splitLocation[1] === "ajustes" ? "bold active open" : "bold"}>
                        <a className="collapsible-header waves-cyan " >
                            <i className="material-icons">settings</i>
                            <span className="menu-title" data-i18n="Menu levels">Ajustes</span>
                        </a>
                        <div className="collapsible-body">
                            <ul className="collapsible collapsible-sub" data-collapsible="accordion">

                                <li className={splitLocation[2] === "ajustes" ? "active" : ""}>
                                    <a className="collapsible-header waves-cyan" >
                                        <i className="material-icons">menu</i>
                                        <span data-i18n="Second level child">Ajustes</span>
                                    </a>
                                    <div className="collapsible-body">
                                        <ul className="collapsible" data-collapsible="accordion">
                                            <li>
                                                <Link to={"/ajustes/ajustes/ajustes-cuenta"} className={splitLocation[3] === "ajustes-cuenta" ? "active gradient-shadow amber darken-3" : ""}
                                                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Ajustes de Cuenta</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/ajustes/ajustes/departamentos"} className={splitLocation[3] === "departamentos" ? "active gradient-shadow amber darken-3" : ""}
                                                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Departamentos</span>
                                                </Link>
                                            </li>
                                            <li style={{
                                                overflow: 'hidden',
                                                whiteSpace: 'nowrap', textOverflow: 'clip'
                                            }}>
                                                <Link to={"/ajustes/ajustes/administradores-y-guardias"} className={splitLocation[3] === "administradores-y-guardias" ? "active gradient-shadow amber darken-3" : ""}
                                                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Personal</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/administracion/torres/torres"} className={splitLocation[3] === "torres" ? "active gradient-shadow amber darken-3" : ""}
                                                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Torres</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/ajustes/ajustes/tipos-vivienda"} className={splitLocation[3] === "tipos-vivienda" ? "active gradient-shadow amber darken-3" : ""}
                                                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Tipos de Vivienda</span>
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to={"/ajustes/ajustes/ajustes-unidades"} className={splitLocation[3] === "ajustes-unidades" ? "active gradient-shadow amber darken-3" : ""}
                                                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Ajustes Unidades</span>
                                                </Link>
                                            </li>


                                            {/*<li>*/}
                                            {/*    <Link to={"/ajustes/ajustes/ajustes-correos"} className={splitLocation[3] === "ajustes-correos" ? "active gradient-shadow amber darken-3" : ""}*/}
                                            {/*        style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                            {/*        <i className="material-icons">radio_button_unchecked</i>*/}
                                            {/*        <span data-i18n="Third level">Ajustes Correos</span>*/}
                                            {/*    </Link>*/}
                                            {/*</li>*/}
                                            <li>
                                                <Link to={"/ajustes/ajustes/guia-telefonica"} className={splitLocation[3] === "guia-telefonica" ? "active gradient-shadow amber darken-3" : ""}
                                                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>
                                                    <i className="material-icons">radio_button_unchecked</i>
                                                    <span data-i18n="Third level">Guia Telefonica</span>
                                                </Link>
                                            </li>
                                            {/*<li>*/}
                                            {/*    <Link to={"/ajustes/ajustes/ajustes-facturacion"} className={splitLocation[3] === "ajustes-facturacion" ? "active gradient-shadow amber darken-3" : ""}*/}
                                            {/*        style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                            {/*        <i className="material-icons">radio_button_unchecked</i>*/}
                                            {/*        <span data-i18n="Third level">Ajustes Facturación</span>*/}
                                            {/*    </Link>*/}
                                            {/*</li>*/}
                                            {/*<li>*/}
                                            {/*    <Link to={"/ajustes/ajustes/ajustes-condopay"} className={splitLocation[3] === "ajustes-condopay" ? "active gradient-shadow amber darken-3" : ""}*/}
                                            {/*        style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                            {/*        <i className="material-icons">radio_button_unchecked</i>*/}
                                            {/*        <span data-i18n="Third level">Ajustes CondoPay</span>*/}
                                            {/*    </Link>*/}
                                            {/*</li>*/}
                                        </ul>
                                    </div>
                                </li>

                                {/*<li className={splitLocation[2] === "editar-registros" ? "active" : ""}>*/}
                                {/*    <a className="collapsible-header waves-cyan" >*/}
                                {/*        <i className="material-icons">menu</i>*/}
                                {/*        <span data-i18n="Second level child">Editar Registros</span>*/}
                                {/*    </a>*/}
                                {/*    <div className="collapsible-body">*/}
                                {/*        <ul className="collapsible" data-collapsible="accordion">*/}
                                {/*            <li>*/}
                                {/*                <Link to={"/ajustes/editar-registros/editar-ingresos"} className={splitLocation[3] === "editar-ingresos" ? "active gradient-shadow amber darken-3" : ""}*/}
                                {/*                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                {/*                    <i className="material-icons">radio_button_unchecked</i>*/}
                                {/*                    <span data-i18n="Third level">Editar Ingresos</span>*/}
                                {/*                </Link>*/}
                                {/*            </li>*/}
                                {/*            <li style={{*/}
                                {/*                overflow: 'hidden',*/}
                                {/*                whiteSpace: 'nowrap', textOverflow: 'clip'*/}
                                {/*            }}>*/}
                                {/*                <Link to={"/ajustes/editar-registros/editar-cuotas-individuales"} className={splitLocation[3] === "editar-cuotas-individuales" ? "active gradient-shadow amber darken-3" : ""}*/}
                                {/*                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                {/*                    <i className="material-icons">radio_button_unchecked</i>*/}
                                {/*                    <span data-i18n="Third level">Editar Cuotas Individuales</span>*/}
                                {/*                </Link>*/}
                                {/*            </li>*/}
                                {/*            <li style={{*/}
                                {/*                overflow: 'hidden',*/}
                                {/*                whiteSpace: 'nowrap', textOverflow: 'clip'*/}
                                {/*            }}>*/}
                                {/*                <Link to={"/ajustes/editar-registros/editar-cuotas-colectivas"} className={splitLocation[3] === "editar-cuotas-colectivas" ? "active gradient-shadow amber darken-3" : ""}*/}
                                {/*                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                {/*                    <i className="material-icons">radio_button_unchecked</i>*/}
                                {/*                    <span data-i18n="Third level">Editar Cuotas Colectivas</span>*/}
                                {/*                </Link>*/}
                                {/*            </li>*/}
                                {/*            <li>*/}
                                {/*                <Link to={"/ajustes/editar-registros/editar-ingresos-extra"} className={splitLocation[3] === "editar-ingresos-extra" ? "active gradient-shadow amber darken-3" : ""}*/}
                                {/*                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                {/*                    <i className="material-icons">radio_button_unchecked</i>*/}
                                {/*                    <span data-i18n="Third level">Editar Ingresos Extra</span>*/}
                                {/*                </Link>*/}
                                {/*            </li>*/}
                                {/*            <li>*/}
                                {/*                <Link to={"/ajustes/editar-registros/editar-egresos"} className={splitLocation[3] === "editar-egresos" ? "active gradient-shadow amber darken-3" : ""}*/}
                                {/*                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                {/*                    <i className="material-icons">radio_button_unchecked</i>*/}
                                {/*                    <span data-i18n="Third level">Editar Egresos</span>*/}
                                {/*                </Link>*/}
                                {/*            </li>*/}
                                {/*            <li>*/}
                                {/*                <Link to={"/ajustes/editar-registros/editar-traspasos"} className={splitLocation[3] === "editar-traspasos" ? "active gradient-shadow amber darken-3" : ""}*/}
                                {/*                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                {/*                    <i className="material-icons">radio_button_unchecked</i>*/}
                                {/*                    <span data-i18n="Third level">Editar Traspasos</span>*/}
                                {/*                </Link>*/}
                                {/*            </li>*/}
                                {/*        </ul>*/}
                                {/*    </div>*/}
                                {/*</li>*/}

                                {/*<li className={splitLocation[2] === "importar-registros" ? "active" : ""}>*/}
                                {/*    <a className="collapsible-header waves-cyan" >*/}
                                {/*        <i className="material-icons">menu</i>*/}
                                {/*        <span data-i18n="Second level child">Importar Registros</span>*/}
                                {/*    </a>*/}
                                {/*    <div className="collapsible-body">*/}
                                {/*        <ul className="collapsible" data-collapsible="accordion">*/}
                                {/*            <li>*/}
                                {/*                <Link to={"/ajustes/importar-registros/importar-unidades"} className={splitLocation[3] === "importar-unidades" ? "active gradient-shadow amber darken-3" : ""}*/}
                                {/*                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                {/*                    <i className="material-icons">radio_button_unchecked</i>*/}
                                {/*                    <span data-i18n="Third level">Importar Unidades</span>*/}
                                {/*                </Link>*/}
                                {/*            </li>*/}
                                {/*            <li>*/}
                                {/*                <Link to={"/ajustes/importar-registros/importar-directorio"} className={splitLocation[3] === "importar-directorio" ? "active gradient-shadow amber darken-3" : ""}*/}
                                {/*                    style={{ background: 'rgba(0, 0, 0, 0) none repeat scroll 0% 0%', boxShadow: 'none' }}>*/}
                                {/*                    <i className="material-icons">radio_button_unchecked</i>*/}
                                {/*                    <span data-i18n="Third level">Importar Directorio</span>*/}
                                {/*                </Link>*/}
                                {/*            </li>*/}
                                {/*        </ul>*/}
                                {/*    </div>*/}
                                {/*</li>*/}
                            </ul>
                        </div>
                    </li>
                }

            </ul>
            <div className="navigation-background"></div>
            <a className="sidenav-trigger btn-sidenav-toggle btn-floating btn-medium waves-light hide-on-large-only" data-target="slide-out">
                <i className="material-icons">menu</i>
            </a>
        </aside>

    )
}


export default withTranslation()(AsideBarComponent)
