import dotenv from 'dotenv';

dotenv.config();

console.log('process.env', process.env);
//process.exit(1);
const isDevelopment = process.env.NODE_ENV === 'development';
const domain = isDevelopment ? process.env.REACT_APP_DEV_URL : process.env.REACT_APP_URL;
const api = isDevelopment ? process.env.REACT_APP_API_DEV : process.env.REACT_APP_API;

const myConfig = {
    PORT: process.env.PORT,
    DEV: isDevelopment,
    APP_URL: domain,
    WEB_URL: process.env.REACT_APP_PAGE_URL,
    API_URL: api,
};
export default myConfig;
